import React from 'react'
import './Stylesheet.css'
import Spinner from '../../Animation/Spinner/Spinner'
// eslint-disable-next-line
import axios from 'axios'
import Swal from 'sweetalert2'

const style={
    margin:'2% 0 0 10%',
    fontWeight:'300',
    fontSize:'50%',
    color:'black'
}
const styleInput={
    height:'5%',
    width:'78%',
    margin:'1% 0 0 10%',
    backgroundColor:'rgb(236, 234, 234)',
    border:'0.5px solid grey',
    padding:'0 1% 0 1%'
}

const sendMail=async(Name,Email,Message)=>{
    await axios.post('https://us-central1-poq-consulting.cloudfunctions.net/sendEmail',{Name:Name,Email:Email,Message:Message}).then((message)=>{
        Swal.fire({
            type: 'success',
            text: 'Successfull',
            showConfirmButton: false,
            timer: 1500
          })
    }).catch(()=>{
        Swal.fire({
            type: 'error',
            text: 'Please Try it later again',
            showConfirmButton: false,
            timer: 1500
          })
    })
}



const Main=()=>{
    const [Name,setName] = React.useState('')
    const [Email,setEmail] = React.useState('')
    const [Message,setMessage] = React.useState('')
    const [submit,setSubmit]=React.useState(true)
    return(
        <div id='FooterContainer'>
            {/* Contact Us */}
            <div className='ContactUs'>
                <div className='ContactUsForm'>
                    <p style={{fontSize:'100%',color:'black',fontWeight:'500',marginLeft:'10%',marginBottom:'0'}}>
                        Contact Us
                    </p>
                    <p style={style}>
                       Fields marked with an <strong style={{color:'red'}}>*</strong> are required 
                    </p>
                    <p style={style}>Name <strong style={{color:'red'}}>*</strong></p>
                    <input style={styleInput} value={Name} onChange={(event)=>setName(event.target.value)}/>
                    <p style={style}>Email <strong style={{color:'red'}}>*</strong></p>
                    <input style={styleInput} value={Email} onChange={(event)=>setEmail(event.target.value)}/>
                    <p style={style}>Message <strong style={{color:'red'}}>*</strong></p>
                    <textarea style={{...styleInput,height:'30%',padding:'1%'}} value={Message} onChange={(event)=>setMessage(event.target.value)}/>
                    <button style={{height:'6%',width:'20%',margin:'auto',borderRadius:'20px',backgroundColor:'rgb(28, 91, 192)',color:'white',border:'0',display:'flex'}} 
                    onClick={async()=>{
                        setSubmit(false)
                        await sendMail(Name,Email,Message)
                        setName('')
                        setMessage('')
                        setEmail('')
                        setSubmit(true)
                    }}> {submit ? <p style={{margin:'auto',fontWeight:'bold'}}>Submit</p>:<Spinner/>}</button>
                </div>
            </div>
            <div className='FooterContainer'>
                {/* Copy right */}
                <div className='FooterContainerText' style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                    <p style={{margin:0}}>© 2019 POQ Consulting</p>
                    <p style={{margin:0}}>Icons made by Freepik,Eucalyp from <a href='https://www.flaticon.com' style={{color:'rgb(0, 119, 255)'}}> www.flaticon.com</a></p>
                </div>

                {/* Linked In link to Matthias Profil */}
                <a href='https://ch.linkedin.com/in/matthias-pohl-69729526?trk=people-guest_profile-result-card_result-card_full-click' target='_blank'  rel="noopener noreferrer"> 
                    <img className='ImageLinkFooter' src="https://image.flaticon.com/icons/svg/174/174857.svg" alt=""/> 
                </a>
                <a href='https://www.xing.com/profile/Matthias_Pohl66' target='_blank'  rel="noopener noreferrer"> 
                    <img className='ImageLinkFooter' src="https://image.flaticon.com/icons/svg/124/124025.svg" alt=""/> 
                </a>
            </div>
        </div>
    )
}

export default Main