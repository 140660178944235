import React from 'react'
import './Stylesheet.css'

const Main = ()=>{
    return(
        <div className='Container' style={{flexDirection:'column'}}>
            {/* Anker */}
            <p id='AnkerAboutUs' style={{position:'absolute',top:'-2.5%'}}></p>
            <p id='AboutUsTitle'>About US</p>
            {/* Content */}
            <div id='AboutUsContainer' style={{display:'flex',justifyContent:'center',alignItems:'center'}}  data-aos="fade-up">
                {/* Text side (Left) */}
                <div id='AboutMeText'>
                    <p>
                    <strong style={{fontSize:'150%',alignSelf:'center'}}>We help you achieve your ambitions</strong> <br/><br/>
                   We support you and your people through complex transitions,
                    which are always both personally, and professionally demanding.<br/><br/> 
                    From over 25 years experience of working in the Pharmaceuticals business,
                    we also appreciate the need to balance external support with internal ownership.
                    We therefore partner with you providing our know-how and proven methods in a style which enables you to eliminate risk, 
                    go faster, realise benefits sooner and achieve sustainable outcomes

                    </p>
                </div>

                {/* Image side (Right) */}
                <div id='AboutMeImage'>
                    <img src='https://www.xing.com/image/a_8_a_715971fd1_24762543_2/matthias-pohl-foto.1024x1024.jpg' alt=''/>
                    <p style={{marginBottom:'0',fontSize:'90%',fontWeight:'bold',marginTop:'1%'}}>Matthias Pohl</p>
                    <hr style={{border:'1px solid black',width:'20%',margin:'1% auto 1% auto'}}/>
                    <p style={{marginTop:'0',fontSize:'70%',fontWeight:'400',paddingBottom:'2%'}}>General Manager</p>
                </div>
            </div>
        </div>
    )
}


export default Main