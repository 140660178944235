import React from 'react'
import './Stylesheet.css'

const Main=()=>{
    return(
        <div className='Container' style={{flexDirection:'column',height:'auto',minHeight:'100vh'}}>
            {/* Anker */}
            <p id='AnkerServices' style={{position:'absolute',top:'-2.5%'}}></p>
            <p className='ServicesTitel'>Services</p>
            <div style={{display:'flex',flexDirection:'column',padding:'0 5% 0 5%'}} data-aos="fade-up">
                <div id='HomeText' >
                    <p>
                        POQ Consulting provides services to the pharmaceutical industry in the areas of Operational Excellence,
                        Quality Management, Health, Safety&Environment, Leadership and Culture Development.<br/>
                        In addition to the services outlined above, we are able to provide tailored services to meet the specific needs of our clients.
                        We have established networks with a number of other consultancy firms and senior experts in the area of pharma, biotech, generics and biosimilars
                        enabling us to support projects according to our clients requirements.
                        For more information, and to discuss what we can do for you, please <a href='#FooterContainer' style={{color:'blue',cursor:'pointer'}}>contact us</a>.
                    </p>
                </div>
            </div>
            <div id='ServicesContainer' data-aos="fade-up" data-aos-duration="1000">
                <div className='SectionContainer'>
                    <div className='TitelContainer'>
                        <img className='ServiceImage' src='https://image.flaticon.com/icons/svg/112/112732.svg' alt=''/>
                        <p className='Titel'>Quality</p>
                    </div>
                    <ul>
                        <li>Quality Systems Development and Implementation</li>
                        <li>Remediation Projects</li>
                        <li>Quality Organization Design and Deployment</li>
                        <li>Inspection Managment</li>
                        <li>Audits</li>
                        <li>Due Diligence</li>
                    </ul>
                </div>
                <div className='SectionContainer'>
                    <div className='TitelContainer'>
                        <img className='ServiceImage' src='https://image.flaticon.com/icons/svg/1459/1459104.svg' alt=''/>
                        <p className='Titel'>Operations</p>
                    </div>
                    <ul>
                        <li>Ad Interim Management</li>
                        <li>Operational Excellence</li>
                        <li>Product Launch Support</li>
                        <li>Health, Safety and Enviorment</li>
                        <li>Project Management including integration, facility start-up, complex change</li>
                    </ul>
                </div>
                <div className='SectionContainer'>
                    <div className='TitelContainer'>
                        <img className='ServiceImage' src='https://image.flaticon.com/icons/svg/476/476700.svg' alt=''/>
                        <p className='Titel'>Leadership</p>
                    </div>
                    <ul>
                        <li>Leadership Coaching</li>
                        <li>Organizational Assessment</li>
                        <li>Transformation of Organizational Culture</li>
                        <li>Aligning Business Strategy with Organizational Culture and Strategic Goals</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Main