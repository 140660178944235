import React from 'react'
import './Stylesheet.css'

const Main = ()=>{
    return(
        <div className='Container' style={{display:'flex',flexDirection:'column'}}>
            {/* Anker */}
            <p id='AnkerPatners' style={{position:'absolute',top:'-2.5%'}}></p>
            <p className='PartnersTitel'>Partners</p>

            <div className='OurPatnersContainer' data-aos="fade-up">
                <div className='OurPatners'>
                    <img className='OurPatnersImg' src={require('../../assets/InkedInterPharmaLink.jpg')} alt=''/>
                    <p className='OurPartnersText'>Inter PharmaLink</p>
                </div>
                <div className='OurPatners'>
                    <img className='OurPatnersImg' src={require('../../assets/Quality Executive Partners.png')} alt=''/>
                    <p className='OurPartnersText'>Quality Executive Partners</p>
                </div>
                <div className='OurPatners'>
                    <img className='OurPatnersImg' src={require('../../assets/TOC Quality Consulting.jpg')} alt=''/>
                    <p className='OurPartnersText'>TOC Quality Consulting</p>
                </div>
                <a href='https://www.conscious-performance.com/'  target='_blank'  rel="noopener noreferrer">
                    <div className='OurPatners'>
                        <img className='OurPatnersImg' src={require('../../assets/conscious-performance.png')} alt=''/>
                        <p className='OurPartnersText'>Conscious Performance</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Main