import React from 'react';
import './Main.css';
import axios from 'axios'

/* import Pages */
import Homepage from './Pages/Homepage/Main'
// eslint-disable-next-line
import Home from './Pages/Home/Main'
import Services from './Pages/Services/Main'
import AboutMe from './Pages/About_US/Main'
import Partners from './Pages/Partners/main'
import Footer from './Pages/Footer/Main'

/* import Navigation */
import HeaderNavigation from './Navigation/HeaderNavigation'

/* The Main Root of the Programm */
class Main extends React.Component{

  componentDidMount(){
    axios.post('https://us-central1-poq-consulting.cloudfunctions.net/increasePageCount',{}).then(data=>{
      console.log(data.data)
    })
  }
  
  render(){
    return (
      <div className="App">
        <HeaderNavigation/>
        <Homepage/>
        <Services/>
        <div className='ParrallaxImage'/>
        <AboutMe/>
        <Partners/>
        <Footer/>
      </div>
    );
  }
}

export default Main;
