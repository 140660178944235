import React from 'react'
import './Stylesheet.css'

/* Globale Variables */
var Y_Value=0;

const Navigation = ()=>{
    return(
        <div id='HeaderContainer'>
            {/* Content */}
            <div id='HeaderBody'>
                {/* Right Side */}
                <div className='NavigationTabs'>
                    {/* Home */}
                    <p className='HeaderText'><a href='#AnkerHome'>Home</a></p>
                    {/* Services */}
                    <p className='HeaderText'><a href='#AnkerServices'>Services</a></p>
                    {/* About Us */}
                    <p className='HeaderText'><a href='#AnkerAboutUs'>About Us</a></p>
                    {/* Team */}
                    <p className='HeaderText'><a href='#AnkerPatners'>Partners</a></p>
                    {/* Contact Us */}
                    <p className='HeaderText'><a href='#FooterContainer'>Contact Us</a></p>
                </div>
            </div>
        </div>
    )
}
window.addEventListener('scroll',(element)=>{
    
    Y_Value=window.pageYOffset

    /* Document var */
    var Navigation=document.getElementsByClassName('NavigationTabs')
    var Header=document.getElementById('HeaderContainer')

    if(Y_Value<10){
        /* Header */
        Header.style.backgroundColor='rgba(46, 43, 43, 0.719)'
        Header.style.height='5%'
        /* Body */
        Navigation[0].style.backgroundColor='transparent'

    }else{
        /* Header */
        Header.style.backgroundColor='transparent'
        Header.style.height='10%'
        /* Body */
        Navigation[0].style.backgroundColor='rgba(46, 43, 43, 0.719)'
    }

})



export default Navigation;