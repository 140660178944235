import React from 'react'
import './Stylesheet.css'

const Main=()=>{
    return(
        /* Container from this Component */
        <div className='Container' style={{minHeight:'100vh'}}>
             {/* Anker */}
             <p id='AnkerHome' style={{position:'absolute',top:'-2.5%'}}></p>
            {/* Background Image */}
            <video autoPlay loop muted src={require('../../assets/BackgroundViedeo.mp4')} style={{position:'absolute',top:'0',left:'0',height:'100%',filter:'blur(10px)',zIndex:'1'}}/>
            <div className="top-panel"></div>
            {/* Content */}
            <div className='HomePageContent'>
                <p id='HomePageTitel'>POQ Consulting GmbH</p>
                <hr/>
                <p id='HomePageText'>Pharmaceutical Operations & Quality Management Excellence</p>
            </div>
        </div>
    )
}



export default Main