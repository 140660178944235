import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './Main';
import * as serviceWorker from './serviceWorker';
/* Scroll Animations */
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init()


ReactDOM.render(<Main />, document.getElementById('root'));

serviceWorker.unregister();
